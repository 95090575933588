






































import Vue from 'vue';
import BizModelSelect from '@/components/elements/BizModelSelect.vue';
import IndustrySelect from '@/components/elements/IndustrySelect.vue';
import MiscMixin from '@/components/mixins/MiscMixin.vue';
import MainFields from '@/components/constructor-product-tab/MainFields.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
    name: 'projectInfoFields',
    mixins: [MiscMixin],
    data(){
        return {
            folded: false
        }
    },
    components: {
        BizModelSelect,
        IndustrySelect,
        MainFields,
    },
    computed: {
        ...mapGetters(['product','projectData'])
    }
})
