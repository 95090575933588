






import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ProductTax } from '@/common/types/product.types';
import taxUtils, { getProductTaxesArr } from '@/common/classes/tax.utils';

export default Vue.extend({
    name: 'bizModelSelect',
    watch: {
        curTax(newTax:ProductTax){ 
            const select = this.$el as HTMLSelectElement;
            select.selectedIndex = this.taxes.findIndex(({type})=>type == newTax.type);
        },
    },
    methods: {
        changeTax(index:number){
            taxUtils.setCurrentTax(this.taxes[index]);
        },
    },
    computed: {
        curTax():ProductTax{
            return taxUtils.getCurrentTax();
        },
        taxes():readonly ProductTax[]{
            return getProductTaxesArr();
        },
        ...mapGetters(['product'])
    }
})
