





























































import Vue from 'vue'
import channelManager from '@/common/classes/channel.manager';
import ProductManager from '@/common/classes/product.manager';
import { ChannelModel, ProductModel } from '@/common/classes/models';
import { smartToFixed } from '@/common/misc';
import { mapGetters } from 'vuex';
import MiscMixin from '@/components/mixins/MiscMixin.vue';
import TaxSelect from '@/components/elements/TaxSelect.vue';
import { formMainFieldTooltip } from '@/common/tooltips';

export default Vue.extend({
    name: 'mainFields',
    mixins: [MiscMixin],
    components: {TaxSelect},
    watch: {
        totalTargetMonthlySales(value:number){
            const {validity} = this.$refs.totalTargetMonthlySalesInput as HTMLInputElement
            const {valid} = validity;
            if(valid) 
                channelManager.updateTotalTargetMonthlySales(value);
        },
        allChannelTargetFractions(fractions:number[]){
            channelManager.updateTotalTargetMonthlySales(this.product.totalTargetMonthlySales);
        },
        isLoadingProject(loading:boolean){
            // if(!loading) this.product.totalTargetMonthlySales = Math.round( this.product.totalTargetMonthlySales );
        }
    },
    methods: {
        mainFieldTltp(key:string){
            return formMainFieldTooltip(key);
        },
        onApcInput(e:any){
            const {value,validity} = e.target as HTMLInputElement;
            const {valid} = validity;
            if(valid) 
                this.$set(this.product,'retention',ProductModel.calcRetentionFromApc(parseFloat(value)))
        },
    },
    computed: {
        showclientsPerSalesUnit():boolean { return ProductManager.showclientsPerSalesUnit(); },
        apc():number{
            return smartToFixed( this.product.getApc(), 2 );
        },
        totalTargetMonthlySales:{
            get():number{
                return this.product.totalTargetMonthlySales;
            },
            cache: false
        },
        //Used for watcher
        allChannelTargetFractions():number[]{
            return (this.channels as ChannelModel[]).map(({targetFraction})=>targetFraction);
        },
        ...mapGetters(['product','channels','isLoadingProject'])
    }
})
